import React, { createContext, useContext, useState } from 'react';

const ContactFormContext = createContext();

export const ContactFormProvider = ({ children }) => {
    const [isContactFormOpen, setIsContactFormOpen] = useState(false);
    const [type, setType] = useState(null);
    const [userRole, setUserRole] = useState(null);


    const handleOpenContactForm = (type, userRole = null) => {
        setType(type);
        setUserRole(userRole);
        setIsContactFormOpen(true);
    };

    const handleCloseContactForm = () => {
        setIsContactFormOpen(false);
        setType(null);
    };

    return (
        <ContactFormContext.Provider value={{ isContactFormOpen, type, userRole, handleOpenContactForm, handleCloseContactForm }}>
            {children}
        </ContactFormContext.Provider>
    );
};

export const useContactForm = () => useContext(ContactFormContext);
