import React, { useState, useEffect } from 'react';
import './MainUsers.css';
import '../../index.css';
import { ReactComponent as SpImage } from '../../assets/svg/sp.svg';
import { ReactComponent as ForInvestor } from '../../assets/svg/forInvestor.svg';
import { useContactForm } from '../ContactSupport/ContactFormContext';

const MainUsers = () => {
    const { handleOpenContactForm } = useContactForm();

    const NavigateToSP = () => {
        window.location.href = "/strategic-partners";
    };

    const NavigateToMarketplace = () => {
        window.location.href = "/marketplace";
    };

    return (
        <div className="main-users">
            <div className="main-users-content">
                <div className="main-users-section">
                    <div className="main-users-texts">
                        <p className="main-title">For Institutional Investors</p>
                        <p className="subtitle">FinBursa’s 360 Solution empowers financial advisory firms, asset managers and institutional investors with a customizable, secure, and private platform to manage clients and exclusive investment opportunities. This all-in-one solution enables partners to create dedicated data rooms, streamline communication, and oversee the full deal lifecycle—supporting smarter, more connected deal management while ensuring institutional-grade privacy and transparency.</p>
                        <div className="button-section">
                            <button className="global-button btn" onClick={NavigateToSP}>Learn more</button>
                            <button className="watch-video-link" onClick={() => handleOpenContactForm("demo")}>
                                <span>Book a Demo</span>
                                <svg
                                    className="icon-stroke"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 8 14"
                                    aria-hidden="true"
                                >
                                    <path
                                        d="m1 13 5.7-5.326a.909.909 0 0 0 0-1.348L1 1"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="SpImage-container">
                        <SpImage />
                    </div>
                </div>
                <div className="main-users-section">
                    <div className="ForInvestor-container">
                        <ForInvestor />
                    </div>
                    <div className="main-users-texts-second">
                        <div className='start'>
                        <p className="main-title">For Investors and Capital Seekers</p>
                        <p className="subtitle">FinBursa's Marketplace connects capital seekers with global investors, advisors, and Strategic Partners. Showcase opportunities, discover tailored projects, and eliminate barriers for seamless capital flow. Our cross-asset platform accelerates fundraising and investments across borders.</p>
                        <div className="button-section">
                            <button className="global-button btn" onClick={NavigateToMarketplace}>Learn more</button>

                        </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};



export default MainUsers;